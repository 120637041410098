#footer {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background-color: #001926;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  border-radius: 10px;
  box-shadow: 0px -2px 2px rgba(50, 50, 50, 0.75);
  text-decoration: none;
}

#footer:hover {
  transform: scale(1.2);
}

.sections {
  width: 100vw;
  height: 100vh;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-width: none;
  margin-left: auto;
  margin-right: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    width: 100%;
    position: relative;
    transition: all 0.5s ease;
  }
}

.fade-in-section {
  opacity: 0;
  transition: opacity 1s ease-in;
  will-change: opacity;
}
.fade-in-section.is-visible {
  opacity: 1;
}
